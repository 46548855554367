import { Dispatch } from 'redux';

import { Device } from '@playq/octopus2-devices';

import { deviceToolkit } from '/store';
import { Event } from '/common/buzzer';

export const updateConnectWithDevice = (dispatch: Dispatch) => {
  return (notification: Device) => {
    const notificationSerialized = notification.serialize();

    dispatch(
      deviceToolkit.actions.updateConnection({
        id: notificationSerialized.id,
        connection: notificationSerialized.connection,
      })
    );
  };
};
export const setStreamEvent = (dispatch: Dispatch) => {
  return (notification: Event) => {
    dispatch(
      deviceToolkit.actions.setEvents({ id: notification.device.serialize(), event: notification.event.serialize() })
    );
  };
};
export const subscribeDevice = (dispatch: Dispatch) => {
  return (device: Device) => {
    dispatch(deviceToolkit.actions.setSubDevices(device.serialize()));
  };
};
export const unsubscribeDevice = (dispatch: Dispatch) => {
  return (id: string) => {
    dispatch(deviceToolkit.actions.unSubDevices(id));
  };
};

export const globalDispatch = (dispatch: Dispatch) => {
  return {
    updateConnectWithDevice: updateConnectWithDevice(dispatch),
    setStreamEvent: setStreamEvent(dispatch),
    subscribeDevice: subscribeDevice(dispatch),
    unsubscribeDevice: unsubscribeDevice(dispatch),
  };
};
