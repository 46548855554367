import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import _ from 'lodash';

import { EphemeralUserAtSerialized, EphemeralUserDisconnectedSerialized } from '@playq/octopus-notifications';

import { RootState } from '/store';
import { appToolkit } from '/store/toolkits/app';
import { Class, CouponsClass } from '/common/models/enums';

export type SetModulePayload = Partial<Omit<ICurrentModuleState, 'onlineUsers'>>;
export type ProcessModuleUserPayload = EphemeralUserAtSerialized;
export type DeleteModuleUser = EphemeralUserDisconnectedSerialized;

export type ModuleUser = EphemeralUserAtSerialized & {
  userID: NonNullable<EphemeralUserAtSerialized['userID']>;
  userName: NonNullable<EphemeralUserAtSerialized['userName']>;
  appID: NonNullable<EphemeralUserAtSerialized['appID']>;
  appName: NonNullable<EphemeralUserAtSerialized['appName']>;
};

export interface ICurrentModuleState {
  moduleClass: Class | CouponsClass | undefined;
  linkToTheLastModulePage?: string;
  entityId?: string;
  disableUserAtNotifications?: boolean;
  onlineUsers: Record<string, ModuleUser>;
  isSpaceLessModule?: boolean;
}

const initialState: ICurrentModuleState = {
  moduleClass: undefined,
  entityId: undefined,
  disableUserAtNotifications: undefined,
  onlineUsers: {},
  isSpaceLessModule: undefined,
};

const slice = createSlice({
  name: 'currentModule',
  initialState,
  reducers: {
    setModule: (state, action: PayloadAction<SetModulePayload | undefined>) => {
      const { moduleClass, entityId, disableUserAtNotifications } = action.payload || {};
      state.moduleClass = moduleClass;
      state.entityId = entityId;
      state.disableUserAtNotifications = disableUserAtNotifications;
    },
    processModuleUser: (state, action: PayloadAction<ProcessModuleUserPayload>) => {
      const ephemeralUserAt = action.payload;
      if (
        !!ephemeralUserAt.userID &&
        !!ephemeralUserAt.userName &&
        ephemeralUserAt.appID !== undefined &&
        !!ephemeralUserAt.appName
      ) {
        const moduleUser: ModuleUser = {
          ...ephemeralUserAt,
          userID: ephemeralUserAt.userID,
          userName: ephemeralUserAt.userName,
          appID: ephemeralUserAt.appID,
          appName: ephemeralUserAt.appName,
        };
        state.onlineUsers[ephemeralUserAt.userID] = moduleUser;
      }
    },
    deleteModuleUser: (state, action: PayloadAction<DeleteModuleUser>) => {
      const ephemeralUserDisconnected = action.payload;
      if (ephemeralUserDisconnected.userID) {
        state.onlineUsers = _.omit(state.onlineUsers, ephemeralUserDisconnected.userID);
      }
    },
    setLinkToTheLastModulePage: (state, action: PayloadAction<string>) => {
      state.linkToTheLastModulePage = action.payload;
    },
    setIsSpaceLessModule: (state, action: PayloadAction<boolean>) => {
      state.isSpaceLessModule = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appToolkit.actions.startAppSelecting, (state) => {
      state.onlineUsers = {};
    });
  },
});
const currentModuleName = (state: RootState) => state.currentModule.moduleClass;
const onlineUsers = (state: RootState) => state.currentModule.onlineUsers;
const isSpaceLessModule = (state: RootState) => state.currentModule.isSpaceLessModule;

const selectModuleUsers = createSelector(currentModuleName, onlineUsers, (currentModule, users): ModuleUser[] => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!currentModule) {
    return [];
  }
  return Object.values(users).filter((onlineUser) => onlineUser.module === currentModule);
});

export const currentModuleToolkit = Object.freeze({
  initialState,
  actions: slice.actions,
  selectors: {
    currentModule: (state: RootState) => state.currentModule,
    currentModuleName,
    onlineUsers,
    selectModuleUsers,
    isSpaceLessModule,
  },
});

export const currentModuleReducer = slice.reducer;
