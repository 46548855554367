import { configureStore } from '@reduxjs/toolkit';

import { services2 } from '/api/services2';

import { listenerMiddleware } from './listener';
import { reducer } from './reducer';
import { systemRehydrate } from './systemActions';
import { logout } from './toolkits/auth/actions';

const prod = __CI_BRANCH__ === 'master';

export const store = configureStore({
  reducer,
  devTools: !prod,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

services2.transport.onFailureEx = ({ code }) => {
  if (code === 401) {
    store.dispatch(logout());
  }
};

// rehydrate the store
store.dispatch(systemRehydrate());
