import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { RootState } from '/store';
import { QueryFilterSerialized, ThemeMode } from '/common/models';
import { SidebarSide } from '/shared/Sidebar/types';

export enum AssetsView {
  List = 'list',
  Masonry = 'masonry',
}

export enum AppsView {
  List = 'list',
  Tile = 'Tile',
}

export interface ITimelineState {
  open: boolean;
  searchMode: boolean;
  filterBy: QueryFilterSerialized;
  moduleTimelineSelected: boolean;
}

export const initialTimelineState: ITimelineState = {
  open: false,
  searchMode: false,
  filterBy: {},
  moduleTimelineSelected: true,
};

export interface IUIState {
  navSidebarOpen: boolean;
  navSidebarSide: SidebarSide;
  timeline: ITimelineState;
  isHints: boolean;
  isSplashScreen: boolean;
  assetsView: AssetsView;
  appsView: AppsView;
  currentThemeMode: ThemeMode;
  isKPIZoomableChartsSessionStarted: boolean;
  isTableTimeRangeRelative: boolean;
  openControlBarPromotionDialog: boolean;

  [key: string]: unknown;
}

export const uiInitialState: IUIState = {
  navSidebarOpen: false,
  navSidebarSide: SidebarSide.Left,
  timeline: initialTimelineState,
  isHints: false,
  isSplashScreen: false,
  assetsView: AssetsView.List,
  appsView: AppsView.List,
  currentThemeMode: ThemeMode.Light,
  isKPIZoomableChartsSessionStarted: false,
  isTableTimeRangeRelative: false,
  openControlBarPromotionDialog: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: uiInitialState,
  reducers: {
    setTimelineState: (state, action: PayloadAction<Partial<ITimelineState>>) => {
      state.timeline = { ...state.timeline, ...action.payload };
    },
    setAssetsView: (state, action: PayloadAction<AssetsView>) => {
      state.assetsView = action.payload;
    },
    setAppsView: (state, action: PayloadAction<AppsView>) => {
      state.appsView = action.payload;
    },
    toggleNavSidebar: (state, action: PayloadAction<boolean>) => {
      state.navSidebarOpen = action.payload;
    },
    setCurrentThemeMode: (state, action: PayloadAction<ThemeMode>) => {
      state.currentThemeMode = action.payload;
    },
    setKPIZoomableChartsSessionStartedStatus: (state, action: PayloadAction<boolean>) => {
      state.isKPIZoomableChartsSessionStarted = action.payload;
    },
    setTableTimeRangeMode: (state, action: PayloadAction<boolean>) => {
      state.isTableTimeRangeRelative = action.payload;
    },
    setOpenControlBarPromotionDialog: (state, action: PayloadAction<boolean>) => {
      state.openControlBarPromotionDialog = action.payload;
    },
    update: (state, action: PayloadAction<Record<string, unknown>>) => {
      _.merge(state, action.payload);
    },
    rehydrate: (state) => {
      _.merge(state, uiInitialState);
    },
  },
});

export const uiToolkit = Object.freeze({
  initialState: uiInitialState,
  actions: uiSlice.actions,
  selectors: {
    state: (state: RootState) => state.ui,
    rehydrate: (state: RootState) => state.ui,
    timeline: (state: RootState) => state.ui.timeline,
    assetsView: (state: RootState) => state.ui.assetsView,
    appsView: (state: RootState) => state.ui.appsView,
    navSidebarOpen: (state: RootState) => state.ui.navSidebarOpen,
    navSidebarSide: (state: RootState) => state.ui.navSidebarSide,
    currentThemeMode: (state: RootState) => state.ui.currentThemeMode,
    isKPIZoomableChartsSessionStarted: (state: RootState) => state.ui.isKPIZoomableChartsSessionStarted,
    isHints: (state: RootState) => state.ui.isHints,
    isSplashScreen: (state: RootState) => state.ui.isSplashScreen,
    isTableTimeRangeRelative: (state: RootState) => state.ui.isTableTimeRangeRelative,
    openControlBarPromotionDialog: (state: RootState) => state.ui.openControlBarPromotionDialog,
    byKey: (key: string) => (state: RootState) => state.ui[key],
  },
});

export const uiReducer = uiSlice.reducer;
