import { createAsyncThunk } from '@reduxjs/toolkit';
import * as _ from 'lodash';

import { App } from '@playq/octopus2-apps';

import { services2 } from '/api';
import { history } from '/Router/history';
import { snackbarService } from '/common/snackbarService';
import { authToolkit } from '/store/toolkits/auth/slice';

import { appToolkit } from './app';

const updateUrl = (appRouteName: string) => {
  if (history.location.pathname.includes('/apps/manage/')) {
    const appEditPage = history.location.pathname.replace(/[^/]*$/, appRouteName);
    history.push(appEditPage);
    return;
  }
  if (history.location.pathname.includes('/configuration/')) {
    history.push(`/apps/${appRouteName}/configuration`);
    return;
  }

  const url = history.location.pathname.replace(/\/apps\/([^/]+)\//, `/apps/${appRouteName}/`);
  history.push(url);
};
export const selectApp = createAsyncThunk('app/selectApp', async ({ app }: { app: App }, thuhnkAPI) => {
  thuhnkAPI.dispatch(appToolkit.actions.startAppSelecting({ app: app.serialize() }));
  try {
    updateUrl(app.routeName);
    thuhnkAPI.dispatch(authToolkit.actions.updateLoginRedirectURL(`/apps/${app.routeName}`));
    const perms = await services2.permsService.getPerms(app.fingerprintID);
    thuhnkAPI.dispatch(appToolkit.actions.updateAccess({ app: app.serialize(), perms: perms.serialize() }));
  } catch (e) {
    const message = _.get(e, 'message', JSON.stringify(e));
    snackbarService.error(`AppSelect: ${message}`);
    console.error('app.select$', e);
    thuhnkAPI.dispatch(appToolkit.actions.failure(message));
  }
});
