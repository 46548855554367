import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TimeIteratorSerialized } from '@playq/octopus-common';

import { RootState } from '/store';
import { getInitialIterator } from '/shared/AppEntityCalendar/helpers/timeHelpers';

export interface ICalendarState {
  experimentsTimeIterator: TimeIteratorSerialized;
  gameEventsTimeIterator: TimeIteratorSerialized;
  pending: boolean;
}

const initialState: ICalendarState = {
  experimentsTimeIterator: getInitialIterator().serialize(),
  gameEventsTimeIterator: getInitialIterator().serialize(),
  pending: false,
};

export enum CalendarTimeIteratorKeys {
  ExperimentsTimeIterator = 'experimentsTimeIterator',
  GameEventsTimeIterator = 'gameEventsTimeIterator',
}

export interface ICalendarSetTimeIteratorPayload {
  key: CalendarTimeIteratorKeys;
  timeIterator: TimeIteratorSerialized;
}

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setTimeIterator: (state, action: PayloadAction<ICalendarSetTimeIteratorPayload>) => {
      const { key, timeIterator } = action.payload;
      state[key] = timeIterator;
    },
  },
});

export const calendarToolkit = Object.freeze({
  initialState,
  actions: calendarSlice.actions,
  selectors: {
    experimentsTimeIterator: (state: RootState) => state.calendar.experimentsTimeIterator,
    gameEventsTimeIterator: (state: RootState) => state.calendar.gameEventsTimeIterator,
  },
});

export const calendarReducer = calendarSlice.reducer;
