import { DeviceSerialized } from '@playq/octopus2-devices';
import { ClientEventSerialized } from '@playq/services-vortex';
interface DeviceInitialState {
  devices: DeviceSerialized[];
  deviceState: [];
  streamEvents: Record<string, ClientEventSerialized[]>;
}
export const deviceState: DeviceInitialState = {
  devices: [],
  deviceState: [],
  streamEvents: {},
};
