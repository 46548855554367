import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  Company,
  CompanySerialized,
  Perms,
  PermsSerialized,
  UserFull,
  UserFullSerialized,
  UserRole,
} from '@playq/octopus2-auth';

import { RootState } from '/store';
import { systemRehydrate } from '/store/systemActions';

import { IAuthState, initialState, IUserInfo } from './state';
import { login, logout } from './actions';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUser: (state, { payload }: PayloadAction<IUserInfo>) => {
      state.user = {
        ...state.user,
        ...payload,
      };
    },
    rehydrate: (state, { payload }: PayloadAction<IAuthState>) => {
      _.merge(state, payload);
    },
    reset: (state) => {
      _.merge(state, initialState, {
        googleRedirectURL: state.googleRedirectURL,
      });
    },
    getPermsByCompany: (state, { payload }: PayloadAction<PermsSerialized>) => {
      state.companyPerms = payload;
    },
    updateRememberMe: (state, { payload }: PayloadAction<boolean>) => {
      state.rememberMe = payload;
    },
    updateGoogleRedirectURL: (state, { payload }: PayloadAction<string | undefined>) => {
      return {
        ...state,
        googleRedirectURL: payload,
      };
    },
    updateLoginRedirectURL: (state, { payload }: PayloadAction<string | undefined>) => {
      return {
        ...state,
        loginRedirectURL: payload === '/login' || payload === '/auth/google' ? state.loginRedirectURL : payload,
      };
    },
    updateServicesAuthorizationStatus: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isServicesAuthorizationSet: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(systemRehydrate.type, (state) => {
      state.pending = true;
    });
    builder.addCase(login.pending, (state, action) => {
      _.merge(state, {
        loggedIn: true,
        ...action.meta.arg.serialize(),
      });
    });
    builder.addCase(logout.pending, (state) => {
      _.merge(state, initialState, {
        rememberMe: state.rememberMe,
        googleRedirectURL: state.googleRedirectURL,
      });
    });
  },
});

const baseSelectors = {
  state: (state: RootState) => state.auth,
  tokensSerialized: (state: RootState) => state.auth.tokens,
  userSerialized: (state: RootState) => state.auth.user,
  companySerialized: (state: RootState): CompanySerialized => state.auth.company,
  role: (state: RootState): UserRole => state.auth.role,
  loggedIn: (state: RootState): boolean => state.auth.loggedIn,
  isServicesAuthorizationSet: (state: RootState): boolean => state.auth.isServicesAuthorizationSet,
  error: (state: RootState): string | undefined => state.auth.error,
  pending: (state: RootState): boolean => state.auth.pending,
  rememberMe: (state: RootState): boolean => state.auth.rememberMe,
  companyPermsSerialized: (state: RootState): PermsSerialized => state.auth.companyPerms,
  googleRedirectURL: (state: RootState): string | undefined => state.auth.googleRedirectURL,
  loginRedirectURL: (state: RootState): string | undefined => state.auth.loginRedirectURL,
};

export const authToolkit = Object.freeze({
  initialState,
  actions: authSlice.actions,
  selectors: {
    ...baseSelectors,
    user: createSelector(baseSelectors.userSerialized, (user: UserFullSerialized) =>
      user?.id ? new UserFull(user) : undefined
    ),
    isAdmin: createSelector(
      baseSelectors.role,
      (role: UserRole) => role === UserRole.Admin || role === UserRole.SuperAdmin
    ),
    company: createSelector(baseSelectors.companySerialized, (c: CompanySerialized) =>
      c?.id ? new Company(c) : undefined
    ),
    companyID: createSelector(baseSelectors.companySerialized, (c: CompanySerialized) => (c?.id ? c.id : undefined)),
    companyPerms: createSelector(baseSelectors.companyPermsSerialized, (p: PermsSerialized) => new Perms(p)),
  },
});

export const authReducer = authSlice.reducer;
