import {
  AuthTokensSerialized,
  CompanyScope,
  CompanySerialized,
  LoginWithEmail,
  LoginWithGoogle,
  Perms,
  PermsSerialized,
  SuccessfulLogin,
  UserFullSerialized,
  UserRole,
} from '@playq/octopus2-auth';

export interface IUserInfo {
  name: string;
  email: string;
}

export interface IAuthState {
  loggedIn: boolean;
  isServicesAuthorizationSet: boolean;
  company: CompanySerialized;
  companyPerms: PermsSerialized;
  user: UserFullSerialized;
  role: UserRole;
  tokens: AuthTokensSerialized;

  pending: boolean;
  rememberMe: boolean;

  error?: string;

  googleRedirectURL?: string;
  loginRedirectURL?: string;
}

export interface ILoginWithEmailRequest {
  loginWithEmail: LoginWithEmail;
  rememberMe: boolean;
}

export interface ILoginWithGoogleRequest {
  loginWithGoogle: LoginWithGoogle;
  rememberMe: boolean;
}

export interface IAuthResponse {
  loginResponse: SuccessfulLogin;
  rememberMe: boolean;
}

const companyPerms = new Perms();
companyPerms.scope = new CompanyScope();
companyPerms.access = {};

export const initialState: IAuthState = {
  loggedIn: false,
  isServicesAuthorizationSet: false,
  company: {
    website: undefined,
    name: '',
    description: undefined,
    id: 0,
    address: undefined,
    phone: undefined,
    servicesId: '',
    routeName: '',
  },
  companyPerms: companyPerms.serialize(),
  user: {
    role: '',
    name: '',
    email: '',
    id: '',
    deleted: undefined,
    mfa: undefined,
  },
  tokens: {
    access: '',
    refresh: '',
  },
  role: UserRole.None,
  pending: false,
  rememberMe: true,
};
