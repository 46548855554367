import { isValidToken } from '/helpers';
import { services2 } from '/api/services2';
import { systemRehydrate } from '/store/systemActions';
import { startAppListening } from '/store/listener';
import { authToolkit, IAuthState } from '/store/toolkits/auth';
import { snackbarService } from '/common/snackbarService';

function parseAuth$(auth: string, fallback: IAuthState): Promise<IAuthState> {
  try {
    const serializedAuth = JSON.parse(auth) as IAuthState | undefined;

    if (!serializedAuth) {
      return Promise.resolve(fallback);
    }

    if (isValidToken(serializedAuth.tokens.access)) {
      return Promise.resolve(serializedAuth);
    }

    if (serializedAuth.tokens.refresh) {
      const { refresh } = serializedAuth.tokens;

      return services2.authService.refreshToken(refresh).then((data) =>
        data.bifold(
          (refreshTR) => ({
            ...serializedAuth,
            tokens: {
              ...serializedAuth.tokens,
              access: refreshTR.access,
            },
          }),
          () => authToolkit.initialState
        )
      );
    }

    const { googleRedirectURL, rememberMe }: IAuthState = serializedAuth;

    return Promise.resolve({
      ...fallback,
      ...(googleRedirectURL ? { googleRedirectURL } : null),
      ...(!rememberMe ? { rememberMe } : null),
    });
  } catch (e) {
    console.error(`Can't read auth state from localStorage`, e);

    return Promise.resolve(fallback);
  }
}

const fetchIniAuthState = (): Promise<IAuthState> => {
  const persistedAuth: string | null = localStorage.getItem('auth');
  const persistedAuthSession: string | null = sessionStorage.getItem('auth');

  const defaultState = authToolkit.initialState;

  if (persistedAuthSession) {
    return parseAuth$(persistedAuthSession, defaultState);
  }

  if (persistedAuth) {
    return parseAuth$(persistedAuth, defaultState);
  }

  return Promise.resolve(defaultState);
};

export const initListeners = () => {
  startAppListening({
    type: systemRehydrate.type,
    effect: async (_action, api) => {
      const state = api.getState();
      const user = authToolkit.selectors.user(state);
      const isAdmin = authToolkit.selectors.isAdmin(state);
      if (!!user && !isAdmin) {
        const perms = await services2.permsService.getPermsByCompany();
        api.dispatch(authToolkit.actions.getPermsByCompany(perms.serialize()));
      }
      fetchIniAuthState()
        .then((authState) => {
          localStorage.setItem('auth', JSON.stringify(authState));
          services2.setAuthorization(authState.tokens.access);
          api.dispatch(authToolkit.actions.rehydrate(authState));
          api.dispatch(authToolkit.actions.updateServicesAuthorizationStatus(true));
        })
        .catch((e: Error) => {
          snackbarService.error(`Login: ${e.message}`);
          console.error('auth.rehydrate$', e);
          api.dispatch(authToolkit.actions.rehydrate(authToolkit.initialState));
        });
    },
  });
};
