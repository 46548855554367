import {
  activeSpaceReducer,
  appReducer,
  authReducer,
  calendarReducer,
  currentModuleReducer,
  kbarReducer,
  uiReducer,
  deviceModuleReducer,
} from './toolkits';

export const reducer = {
  kbar: kbarReducer,
  ui: uiReducer,
  activeSpace: activeSpaceReducer,
  auth: authReducer,
  app: appReducer,
  currentModule: currentModuleReducer,
  devices: deviceModuleReducer,
  calendar: calendarReducer,
};
