import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { Space, SpaceSerialized } from '@playq/octopus2-apps';

import { RootState } from '/store';

interface IActiveSpaceState {
  activeSpace: SpaceSerialized | undefined;
}

const initialState: IActiveSpaceState = {
  activeSpace: undefined,
};

const activeSpaceSlice = createSlice({
  name: 'activeSpace',
  initialState,
  reducers: {
    reset: () => initialState,
    put: (state, action: PayloadAction<SpaceSerialized | undefined>) => {
      state.activeSpace = action.payload;
    },
  },
});

export const activeSpaceToolkit = Object.freeze({
  initialState,
  actions: activeSpaceSlice.actions,
  selectors: {
    activeSpace: (state: RootState) =>
      !_.isNil(state.activeSpace.activeSpace) ? new Space(state.activeSpace.activeSpace) : undefined,
  },
});

export const activeSpaceReducer = activeSpaceSlice.reducer;
