import { SpaceID } from '@playq/octopus-common';

import { storage } from '/storage';
import { services2 } from '/api/services2';
import { snackbarService } from '/common/snackbarService';
import { startAppListening } from '/store/listener';
import { authToolkit } from '/store/toolkits/auth';
import { activeSpaceToolkit } from '/store/toolkits/space';

export const initListeners = () => {
  startAppListening({
    actionCreator: authToolkit.actions.rehydrate,
    effect: (_action, api) => {
      const serializedSpaceId = storage.get('activeSpaceID') || undefined;
      if (!serializedSpaceId) {
        api.dispatch(activeSpaceToolkit.actions.put(undefined));
        return;
      }
      let spaceID;
      try {
        spaceID = new SpaceID(serializedSpaceId);
      } catch (e) {
        api.dispatch(activeSpaceToolkit.actions.put(undefined));
        console.error(`Can't parse space id from storage`);
        return;
      }

      services2.spacesService
        .retrieve(spaceID)
        .then((data) =>
          data.bifold(
            (space) => api.dispatch(activeSpaceToolkit.actions.put(space.serialize())),
            (err) => {
              throw err;
            }
          )
        )
        .catch((e: Error) => {
          snackbarService.error(`Space Rehydrate: ${e.message}`);
          console.error('space.rehydrate$', e);
          api.dispatch(activeSpaceToolkit.actions.put(undefined));
        });
    },
  });
};
