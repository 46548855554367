import { getUrlAppName } from '/helpers';
import { preferredThemeModeLocalStorageKey } from '/constants';
import { startAppListening } from '/store/listener';
import { authToolkit } from '/store/toolkits/auth';
import { ThemeMode } from '/common/models';

import { IUIState, uiToolkit, uiInitialState } from '../ui/ui';

function getInitUIState(userID: string): IUIState {
  const persistedThemeModelessUI: string | null = localStorage.getItem(`ui:${userID}`);
  const persistedThemeMode: ThemeMode = localStorage.getItem(preferredThemeModeLocalStorageKey) as ThemeMode;

  if (!persistedThemeModelessUI) {
    return uiInitialState;
  }

  try {
    const uiState = JSON.parse(persistedThemeModelessUI) as undefined | IUIState;
    if (!uiState) {
      return uiInitialState;
    }

    uiState.currentThemeMode = persistedThemeMode;
    return uiState;
  } catch (e) {
    console.error(`Can't read ui state from localStorage`, e);

    return uiInitialState;
  }
}
export const initListeners = () => {
  startAppListening({
    actionCreator: authToolkit.actions.rehydrate,
    effect: (action, api) => {
      const urlAppName = getUrlAppName(window.location.pathname);
      const isLoginPage = window.location.pathname.includes('login');
      const userID = action.payload.user.id;
      const uiState = { ...getInitUIState(userID) };

      if (!isLoginPage && !urlAppName) {
        uiState.timeline.open = false;
      }
      api.dispatch(uiToolkit.actions.update(uiState));
    },
  });
};
