import { Config } from '@dyssent/blankjs';

export const appBlankStyle: Config = {
  shapes: [
    {
      type: 'rectangle',
      width: {
        left: 128,
        right: 128,
      },
      style: {
        fill: {
          type: 'palette',
          palette: {
            type: 'builtin',
            id: 'blue5',
          },
        },
      },
      modifiers: {
        replication: {
          count: {
            left: 1,
            right: 1,
          },
        },
        transforms: {
          translate: {
            modifier: 'translate',
            x: {
              left: 0,
              right: 0,
              step: 0,
            },
            y: {
              left: 0,
              right: 0,
              step: 0,
            },
          },
          scale: {
            modifier: 'scale',
            x: {
              left: 1,
              right: 1,
            },
          },
        },
      },
    },
    {
      type: 'rectangle',
      width: {
        left: 64,
        right: 64,
      },
      height: {
        left: 96,
        right: 94,
      },
      borderRadius: {
        left: 5,
        right: 5,
      },
      style: {
        fill: {
          type: 'custom',
          value: 'rgba(255, 255, 255, 1)',
        },
        strokeWidth: {
          left: 8,
          right: 8,
        },
      },
      modifiers: {
        replication: {
          count: {
            left: 1,
            right: 1,
          },
        },
        transforms: {
          translate: {
            modifier: 'translate',
            x: {
              left: -24,
              right: 24,
            },
            y: {
              left: -24,
              right: 24,
            },
          },
          rotate: {
            modifier: 'rotate',
            origin: {
              x: {
                left: 64,
                right: 64,
              },
              y: {
                left: 64,
                right: 64,
              },
            },
            angle: {
              left: -45,
              right: 45,
            },
          },
          scale: {
            modifier: 'scale',
            x: {
              left: 1,
              right: 1,
            },
          },
        },
      },
    },
    {
      type: 'text',
      size: {
        left: 48,
        right: 48,
      },
      style: {
        fill: {
          type: 'palette',
          palette: {
            type: 'builtin',
            id: 'blue5',
          },
        },
        strokeWidth: {
          left: 1,
          right: 1,
        },
      },
      modifiers: {
        replication: {
          count: {
            left: 1,
            right: 5,
          },
        },
        transforms: {
          translate: {
            modifier: 'translate',
            x: {
              left: 0,
              right: 0,
              step: 0,
            },
            y: {
              left: 0,
              right: 0,
              step: 0,
            },
          },
          scale: {
            modifier: 'scale',
            x: {
              left: 1,
              right: 1,
            },
          },
        },
      },
    },
  ],
  palettes: {},
};
