// listenerMiddleware.ts
import { createListenerMiddleware, addListener } from '@reduxjs/toolkit';
import type { TypedStartListening, TypedAddListener } from '@reduxjs/toolkit';

import { initListeners as initAuthListeners } from '/store/toolkits/auth/listeners';
import { initListeners as initAppListeners } from '/store/toolkits/app/listeners';
import { initListeners as initSpaceListeners } from '/store/toolkits/space/listeners';
import { initListeners as initUIListeners } from '/store/toolkits/ui/listeners';

import type { RootState, AppDispatch } from './RootState';

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>;

initAppListeners();
initAuthListeners();
initSpaceListeners();
initUIListeners();
