import { isAnyOf } from '@reduxjs/toolkit';

import { AppName } from '@playq/octopus2-apps';
import { GenericFailure, GenericFailureCode } from '@playq/services-shared';

import { getUrlAppName } from '/helpers';
import { services2 } from '/api/services2';
import { startAppListening } from '/store/listener';
import { authToolkit } from '/store/toolkits/auth';
import { login } from '/store/toolkits/auth/actions';
import { history } from '/Router/history';
import { snackbarService } from '/common/snackbarService';

import { appToolkit } from './app';

const isAccessDenied = (code: GenericFailureCode) =>
  code === GenericFailureCode.AccessDenied || code === GenericFailureCode.EntityNotFound;

export const initListeners = () => {
  startAppListening({
    matcher: isAnyOf(authToolkit.actions.rehydrate, login.fulfilled),
    effect: (_action, api) => {
      const state = api.getState();

      const appFromUrl = getUrlAppName(window.location.pathname);
      if (!!appFromUrl && appFromUrl !== 'manage' && state.auth.loggedIn) {
        const fullAppName = new AppName({
          name: appFromUrl,
          company: authToolkit.selectors.companyID(state) as number,
        });
        services2.appsService
          .retrieve(fullAppName, [])
          .then(async (data) =>
            data.bifold(
              (app) =>
                services2.permsService.getPerms(app.fingerprintID).then((perms) => {
                  api.dispatch(appToolkit.actions.updateAccess({ app: app.serialize(), perms: perms.serialize() }));
                }),
              (err) => {
                throw err;
              }
            )
          )
          .catch((err: Error | GenericFailure) => {
            snackbarService.error(`Can't rehydrate app: ${fullAppName.name}. msg: ${err.message}`);
            console.error('app.retrieve$', err);

            if (err instanceof GenericFailure && isAccessDenied(err.code)) {
              const currentApp = appToolkit.selectors.app(state);
              const isSameApp = fullAppName.name === currentApp?.routeName;
              if (isSameApp) {
                return api.dispatch(appToolkit.actions.resetApp());
              }

              history.push('/');
            }
          });
      }
    },
  });
};
