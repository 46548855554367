import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { UnknownDevice, KnownDevice, DeviceSerialized, DeviceConnectionSerialized } from '@playq/octopus2-devices';
import { ClientEventSerialized } from '@playq/services-vortex';

import { RootState } from '/store/RootState';

import { deviceState } from './state';

const deviceSlice = createSlice({
  name: 'devices',
  initialState: deviceState,
  reducers: {
    setSubDevices: (state, action: PayloadAction<DeviceSerialized>) => {
      const device = action.payload;
      state.devices = [device];
    },
    unSubDevices: (state, action: PayloadAction<string>) => {
      const deviceId = action.payload;
      state.devices = state.devices.filter((device) => device.id !== deviceId);
    },
    updateSubscriptionName: (state, action: PayloadAction<{ id: string; name: string }>) => {
      state.devices = state.devices.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, name: action.payload.name };
        }
        return item;
      });
    },
    setEvents: (state, action: PayloadAction<{ id: string; event: ClientEventSerialized }>) => {
      state.streamEvents[action.payload.id] =
        state.streamEvents[action.payload.id] !== undefined
          ? [action.payload.event, ...state.streamEvents[action.payload.id]]
          : [action.payload.event];
    },
    updateConnection: (
      state,
      action: PayloadAction<{ id: string; connection: DeviceConnectionSerialized | undefined }>
    ) => {
      state.devices = state.devices.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, connection: action.payload.connection };
        }
        return item;
      });
    },
  },
});
const baseSelectors = {
  devices: (state: RootState) => state.devices.devices,
  deviceState: (state: RootState) => state.devices,
  streamEvents: (state: RootState) => state.devices.streamEvents,
};
const subscribtionDevice = createSelector(baseSelectors.devices, (device) => {
  if (device.length !== 0) {
    return {
      name: 'name' in device[0] ? new KnownDevice(device[0]).name : new UnknownDevice(device[0]).alias.installationId,
      device: 'name' in device[0] ? new KnownDevice(device[0]) : new UnknownDevice(device[0]),
    };
  }
});

export const deviceToolkit = Object.freeze({
  deviceState,
  actions: deviceSlice.actions,
  selectors: {
    ...baseSelectors,
    subscribtionDevice,
  },
});

export const deviceModuleReducer = deviceSlice.reducer;
