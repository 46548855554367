import { SuccessfulLogin } from '@playq/octopus2-auth';

import { services2 } from '/api/services2';
import { history } from '/Router/history';
import { createAppAsyncThunk } from '/store/RootState';
import { createStorage, sessionStorageMethods } from '/storage';
import { PersistedQueryKeys } from '/common/models';
import { snackbarService } from '/common/snackbarService';

import { authToolkit } from './slice';

const storage = createStorage({ prefix: PersistedQueryKeys.StoragePrefix, storage: sessionStorageMethods });

export const login = createAppAsyncThunk('auth/login', (res: SuccessfulLogin, api) => {
  services2.setAuthorization(res.tokens.access);
  const state = api.getState();
  const user = authToolkit.selectors.user(state);
  const isAdmin = authToolkit.selectors.isAdmin(state);

  if (user && !isAdmin) {
    services2.permsService
      .getPermsByCompany()
      .then((perms) => {
        api.dispatch(authToolkit.actions.getPermsByCompany(perms.serialize()));
        api.dispatch(authToolkit.actions.updateServicesAuthorizationStatus(true));
      })
      .catch((e: Error) => {
        snackbarService.error(`GetPermsByCompany: ${e.message}`);
        console.error('auth.getPermsByCompany', e);
      });
  }
});

export const logout = createAppAsyncThunk('auth/logout', (_action, api) => {
  const loginRedirectURL = window.location.pathname;
  storage.flush();
  history.push('/login');
  services2.clearCache();
  services2.resetAuthorization();
  api.dispatch(authToolkit.actions.updateLoginRedirectURL(loginRedirectURL));
  api.dispatch(authToolkit.actions.updateServicesAuthorizationStatus(false));
});
