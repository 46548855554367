import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppSerialized, App } from '@playq/octopus2-apps';
import { AppID, Tag } from '@playq/octopus-common';
import { PermsSerialized, Perms, UserRole, AccessLevel, PermModule } from '@playq/octopus2-auth';

import { getUrlAppName } from '/helpers';
import { RootState } from '/store';
import { systemRehydrate } from '/store/systemActions';

import { authToolkit } from '../auth/slice';
import { login } from '../auth/actions';

export interface IAppState {
  app?: AppSerialized;
  perms?: PermsSerialized;
  pending: boolean;
  error?: string;
}

export interface IAppSelectPayload {
  app: AppSerialized;
}

export interface IModulePerms {
  canRead: boolean;
  canWrite: boolean;
  canWriteLive: boolean;
}

const initialState: IAppState = {
  pending: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateApp: (state, { payload }: PayloadAction<AppSerialized>) => {
      state.app = payload;
    },
    updatePerms: (state, { payload }: PayloadAction<Perms>) => {
      state.perms = payload.serialize();
    },
    resetApp: () => initialState,
    failure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.pending = false;
    },
    updateAccess: (state, { payload }: PayloadAction<{ app: AppSerialized; perms: PermsSerialized }>) => {
      const { app, perms } = payload;
      state.app = app;
      state.perms = perms;
      state.error = undefined;
      state.pending = false;
    },
    startAppSelecting: (state, { payload }: PayloadAction<IAppSelectPayload>) => {
      state.app = payload.app;
      state.pending = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        const isApp = !!getUrlAppName(window.location.pathname);
        state.pending = isApp;
      })
      .addCase(systemRehydrate, (state) => {
        const isApp = !!getUrlAppName(window.location.pathname);
        state.pending = isApp;
      });
  },
});

const baseSelectors = {
  state: (state: RootState) => state.app,
  appRaw: (state: RootState) => state.app.app,
  appIDRaw: (state: RootState) => state.app.app?.id,
  appFingerprintIDRaw: (state: RootState) => state.app.app?.fingerprintID,
  error: (state: RootState) => state.app.error,
  permsRaw: (state: RootState) => state.app.perms,
};

const perms = createSelector(baseSelectors.permsRaw, (rawPerms) => (rawPerms ? new Perms(rawPerms) : undefined));

const accessScope = createSelector(
  authToolkit.selectors.companyPerms,
  baseSelectors.appRaw,
  perms,
  (companyPerms: Perms, app, appPerms): Record<string, AccessLevel> =>
    app && appPerms ? { ...companyPerms.access, ...appPerms.access } : companyPerms.access
);

const moduleAccess = (mod: PermModule) =>
  createSelector(authToolkit.selectors.role, accessScope, (role, scope): IModulePerms => {
    const access = role === UserRole.Admin || role === UserRole.SuperAdmin ? AccessLevel.WriteLive : scope[mod];

    return {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      canRead: !!access && access !== AccessLevel.None,
      canWrite: access === AccessLevel.Write || access === AccessLevel.WriteLive,
      canWriteLive: access === AccessLevel.WriteLive,
    };
  });

const moduleAccessEx = (mod: PermModule) =>
  createSelector(authToolkit.selectors.role, perms, (role: UserRole, p?: Perms) => {
    if (role === UserRole.Admin || role === UserRole.SuperAdmin) {
      return AccessLevel.WriteLive;
    }

    const map = p?.access ?? {};
    const access = map[mod];
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!access) {
      return AccessLevel.None;
    }

    return access;
  });

export const appToolkit = Object.freeze({
  initialState,
  actions: appSlice.actions,
  selectors: {
    ...baseSelectors,
    app: createSelector(baseSelectors.appRaw, (rawApp) => (rawApp ? new App(rawApp) : undefined)),
    appRouteName: createSelector(baseSelectors.appRaw, (rawApp) => (rawApp ? rawApp.routeName : undefined)),
    appAsTag: createSelector(baseSelectors.appIDRaw, (rawID) =>
      rawID ? new Tag({ key: 'app', value: new AppID(rawID).id.toString() }) : undefined
    ),
    appID: createSelector(baseSelectors.appIDRaw, (rawID) => (rawID ? new AppID(rawID) : undefined)),
    perms,
    accessScope,
    moduleAccess,
    moduleAccessEx,
  },
});

export const appReducer = appSlice.reducer;
